export default class CustomEvents {
  static EVENTS_KEY = {
    'no-car': 'no-car',
    'filters-changed': 'filters-changed',
    'search-form-input-changed': 'search-form-input-changed',
    'gpay-response': 'gpay-response',
    'multiselect-closed': 'multiselect-closed',
    'multiselect-ready': 'multiselect-ready',
    'multiselect-changed': 'multiselect-changed',
    'search-form-changed': 'search-form-changed',
    'search-form-reset': 'search-form-reset',
    'prefilter-changed': 'prefilter-changed',
    'search-form-submitted': 'search-form-submitted',
    'turbo-refresh': 'turbo-refresh',
    'register-form-changed': 'register-form-changed',
    'validation-phone-form-loaded': 'validation-phone-form-loaded'
  }

  static getEvent(key, payload = null){
    return new CustomEvent(key, payload)
  }

  searchFormInputChangedEvent () {
    return new CustomEvent(CustomEvents.EVENTS_KEY['search-form-input-changed'])
  }

}
